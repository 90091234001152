:root {
    --primary: #0180DD;
    --secondary: #858C90;
    --plain: #FFF;
    --black: #323232;
    --license: #575757;
    --license-list: #60A7DB;
    --background: #011D32;
    --contact: #4F96CA;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  font-family: 'Inter', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  scroll-behavior: smooth;
}

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

h2 {
  font-weight: 900;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
}

button {
  width: 150px; 
  border: 1px solid transparent; 
  border-radius: 30px; 
  font-weight: 800;
  letter-spacing: 1px; 
  background-color: var(--primary);
  padding:8px; 
  font-size: 18px;
  color: var(--plain);
  text-transform: uppercase;
  margin-top: 35px;
}

button:hover {
  cursor: pointer;
}

.par-txt {
  font-family: 'Open Sans', sans-serif;
  font-weight: 500;
  font-size: 22px;
  color: var(--black);
  letter-spacing: 1px;
  margin: 0 auto;
}

.license-txt {
  font-family: 'Open Sans', sans-serif;
  color: var(--license);
  font-size: 18px;

}

input {
  border:none;
}

input:focus {
  outline: none;
}

.btn-scroll {
  cursor: pointer;
}

.hide-desktop {display: none;}

/* #light {background-color: #f3ebf6;}
#dark {background: #46045e no-repeat fixed center; } */



/*--------------RESPONSIVE CSS ----------------*/
@media screen and (max-width:940px) {
  .hide-mobile {display: none;}
  .hide-desktop {display: block;}
  .btn-scroll,
  .btn-show {width: 25px;}

  /* #sidenav-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 997;
    display: none;
  } */
}