.header {
    position: fixed;
    height: 90px;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 1;
    /* transition: .3s ease-in; */
    overflow: hidden;
    background-color: var(--plain);
}

.header .navbar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    max-width: 1550px;
    margin: auto;
    height: 100%;
    padding: 0 1rem;
}

.header .nav-menu a {
    color: var(--primary);
    text-decoration: none;
    font-weight: 700;
    font-size: 16px;
}

.header .navbar img {
    width: 250px;
    height: auto;
}

.header .nav-menu {
    display: flex;
}

.header .nav-item {
    padding: 1rem 1.5rem;
    font-weight: 500;
}

.header .nav-item a:hover {
    padding-bottom: 12px;
    border-bottom: 3px solid var(--primary);
}

.hamburger {
    display: none;
}


/*---------------------RESPONSIVE CSS-------------------------*/
@media screen and (min-width:1024px) {
    .header .nav-item {padding: 0rem 1rem;}
}

@media screen and (max-width:940px) {
    .header {max-width: 100%;height: 70px;}
    .header .navbar {max-width: 100%;position: relative;}
    .hamburger {display: block;}
    .hamburger .btn-fatimes {position: absolute;left: 3%;top: 10%;z-index: 1;}
    .nav-menu {
        position: fixed;
        left: -100%;
        top: 90px;
        flex-direction: column;
        width: 100%;
        height: 90vh;
        z-index: 1;
        text-align: center;
        /* transition: .3s; */
    }

    .nav-menu.active {
        left: 40%;
        top: 0;
        background-color: var(--plain);
        width: 100%;
        height: 100vh;
        float: right;
        z-index: 1;
        /* transition: none; */
        text-align: left;
    }

    .nav-menu.active .prime-details {padding: 0 1.5rem;position: absolute;bottom: 10%;}
    .nav-menu.active .prime-details p {font-size: 14px;font-weight: 600;color: var(--primary);margin-bottom: 10px;}
    .nav-menu.active .prime-details ul {display: inline-flex;}
    .nav-menu.active .prime-details ul li img {width: 40px;margin-right: 5px;}
    .header .navbar img {width: 175px;}

    .overlay {
        position: absolute;
        top: 0;
        background-color: black;
        width: 100%;
        height: 100vh;
        opacity: 0.8;
        z-index: 0;
        display: none;}
}

@media screen and (max-width:940px) and (orientation: landscape) {
    .header .nav-item {padding: 1rem 1.5rem 0.5rem; width: 50%;}
    .nav-menu.active {left: 30%;width: 70%;z-index: 3;}
    .nav-menu.active .prime-details {bottom: unset;right: 8%;top: 11%;border-left: 1px solid var(--black);}
}