#privacy .hero {
    height: 55vh;
}

#privacy .hero .content h1 {
    margin-top: 80px;
}

#privacy .privacy .content {
    height: 90%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1100px;
    margin: auto;
    padding: 3rem 1rem;
    text-align: center;
    color: var(--plain);
}

#privacy .privacy .content .privacy-container-heading {
    display: inline-flex;
    margin: 0 auto;
    padding-bottom: 50px;
    
}

#privacy .privacy .content .privacy-container-heading h2 {
    font-size: 50px;
    font-weight: 900;
}

#privacy .privacy .content .privacy-container-heading h2:first-child {
    color: var(--primary);
    padding-right: 10px;
}

#privacy .privacy .content .privacy-container-heading h2:nth-child(2) {
    color: var(--secondary);
}

#privacy .privacy .content p,
#privacy .privacy .content li {
    color: var(--black);
    text-align: left;
    line-height: 25px;
}

#privacy .privacy .content ol {
    padding-left: 30px;
}

#privacy .privacy .content li {
    margin-bottom: 10px;
}

#privacy .privacy .content h3 {
    color: var(--primary);
    text-align: left;
    text-transform: uppercase;
    margin-top: 30px;
    margin-bottom: 15px;
}

#privacy .privacy .content a:hover {
    color: var(--black);
}

#privacy .privacy .content h4 {
    color: var(--black);
    text-align: left;
    margin:10px 0;
    font-weight: 600;
}

/*--------------RESPONSIVE CSS ----------------*/
@media screen and (max-width:940px) {
    #privacy .privacy .content .privacy-container-heading h2 {font-size: 40px;}
}

@media screen and (max-width:375px) {
    #privacy .privacy .content .privacy-container-heading h2 {font-size: 35px;}
}
