#timeline::before {
    content: '';
    display: block;
    height: 55px;
    margin-top: -55px;
    visibility: hidden;
}

.timeline {
    background: url('../img/timeline-bg.jpg') center center/cover no-repeat; 
    height: 100vh; 
    width: 100%; 
    position: relative;
}

.timeline .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1300px;
    margin: auto;
    text-align: center;
    color: var(--plain);
}

.timeline .content .timeline-container-heading {
    display: inline-flex;
    margin: 0 auto;
    
}

.timeline .content .timeline-container-heading h2 {
    font-size: 70px;
    margin-bottom: 10px;
}

.timeline .content .timeline-container-heading h2:first-child {
    color: var(--primary);
}

.timeline .content .timeline-container-heading h2:nth-child(2) {
    color: var(--secondary);
}

.timeline .content img {
    width: 100%;
}

.timeline .content .timeline-btn {
    position: absolute;
    bottom: 3%;
    left: 0;
    right: 0;
}

/*--------------RESPONSIVE CSS ----------------*/
@media screen and (max-width:940px) {
    .timeline {background: url('../img/mobile/timeline-bg-m.jpg') center center/cover no-repeat; height: auto;}
    .timeline .content {padding: 2rem 0;}
    .timeline .content .timeline-container-heading h2 {font-size: 40px;}
    .timeline .content .timeline-container-heading {padding: 0 0 3rem 0;}
    .timeline .content .time-mob {width: auto; margin: 0 auto; padding: 0 0 4rem 0;}
    .timeline .content .timeline-btn {display: none;}
}