#brands::before {
    content: '';
    display: block;
    height: 75px;
    margin-top: -75px;
    visibility: hidden;
}

.brand {
    /* height: 100vh;  */
    width: 100%; 
    /* position: relative; */
    border-bottom: 5px dotted #60A7DB;
}

.brand .content {
    /* height: 40%; */
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* max-width: 1300px; */
    margin: auto;
    padding: 60px 0 2.5rem 0;
    text-align: center;
    color: var(--plain);
    padding-bottom: 0;
}

.brand .content .our-brand-container-heading {
    display: inline-flex;
    margin: 0 auto;
    padding-bottom: 60px;
    
}

.brand .content .par-txt {
    max-width: 1100px;
    padding: 0 0.5rem;
}

.brand .content .our-brand-container-heading h2 {
    font-size: 70px;
    margin-bottom: 0;
}

.brand .content .our-brand-container-heading h2:first-child {
    color: var(--secondary);
    padding-right: 10px;
}

.brand .content .our-brand-container-heading h2:nth-child(2) {
    color: var(--primary);
}

.product {
    width: 100%;
    margin-top: 80px;
}

.product .container {
    /* width: 1240px; */
    display: grid;
    grid-template-columns: 50% 50%;
}

.product .col-1 {
    display: flex;
    flex-direction: column;
}

.product .col-1 img {
    width: 100%;
}

.product .col-2 {
    margin: auto;
    /* padding: 0 200px; */
}

.product .col-2 .par-txt {
    width: 60%;
    margin-top: 20px;
}

.product .col-2 .our-brands-license {
    margin-top: 2rem;
}

.product .col-2 .our-brands-license p {
    color: #575757;
}

.product .col-2 .our-brands-license ul {
    margin-top: 2rem;
}

.product .col-2 .our-brands-license li {
    display: inline-flex; 
    font-weight: 900; 
    font-size: 20px; 
    color: var(--license-list)
}

.product .col-2 .our-brands-license a {
    border-right: 1px solid var(--license-list);
    padding: 0.3rem 1rem;
    height: 30px;
}

.product .col-2 .our-brands-license a:last-child {
    border-right: none;
}

.product .showmore-container {
    display: block;
    margin: 100px 0;
}

.product .showmore-container .showmore-txt{
    font-weight: 800;
    letter-spacing: 2px; 
    font-size: 18px; 
    color: var(--primary);
    text-transform: uppercase;
    margin-bottom: 5px;

}

.product .showmore-container .btn-show:hover{
    cursor: pointer;
}


/*--------------RESPONSIVE CSS ----------------*/
@media screen and (max-width:1366px) {
    .brand .content {padding: 50px 0;}
    .product .col-2  img {width: auto;}
}

@media screen and (max-width:1024px) {
    .brand .content {padding: 50px 0;}
    .product .col-2 .par-txt {font-size: 15px;padding: 0;}
    .product .col-2 .our-brands-license {margin-top: 1rem;}
    .product .col-2 .our-brands-license ul {margin-top: 1rem;}
    .product .col-2 .our-brands-license li {font-size: 15px;}
    .product .col-2 .our-brands-license a {padding: 0 0.7rem;}
    .product .col-2 .our-brands-license button {width: 130px;margin-top: 15px;font-size: 16px;}
    .product .col-2  img {width: 60%;}
}

@media screen and (max-width:940px) {
    .brand {margin: 1rem 0;}
    .brand .content .our-brand-container-heading h2 {font-size: 40px;}
    .brand .content .par-txt{padding-top:20px;}
    .brand .content .par-txt,
    .brand .content .product .col-2 .par-txt,
    .brand .content .product .col-2 .license-txt,
    .brand .content .product .col-2 ul li {font-size: 16px;}
    
    .brand .content .product .container {display: flex; flex-wrap: wrap;}
    .brand .content .product .container .col-2 img{max-width: 200px;}
    .brand .content .product .container:last-child {margin-bottom: 60px;}
    .brand .content .product .container:nth-child(2) .col-1 {order: 1;}
    .brand .content .product .container:nth-child(2) .col-2 {order: 2;}
    .brand .content .product .container:nth-child(4) .col-1 {order: 1;}
    .brand .content .product .container:nth-child(4) .col-2 {order: 2;}
    .brand .content .product .container:nth-child(6) .col-1 {order: 1;}
    .brand .content .product .container:nth-child(6) .col-2 {order: 2;}

    .product {margin-top: 35px;}
    .product .col-2 {padding: 3rem;}
    .product .col-2 .our-brands-license,
    .product .col-2 .our-brands-license ul {margin-top: 15px;}
    .product .col-2 .our-brands-license button {margin-top: 30px;}

    .product .showmore-container {margin: 70px 0;}

    .product .col-2 .par-txt {
        width: 100%; margin: 0;}
    
    .brand .content {padding: 30px 0 2.5rem 0;}
    .brand .content .our-brand-container-heading {padding-bottom: 30px;}
    .brand .content .par-txt {padding: 0 1rem;}
    
}

@media screen and (max-width:320px) {
    .nav-menu.active {left: 32% !important;}
    .product .col-2 .our-brands-license a {padding: 0.3rem 0.5rem;}
    .contact .prime-details h3 {font-size: 30px !important;}
    .contact .container .col-3 p {font-size: 13px !important;}
    .contact .container .col-2 h3 {font-size: 25px !important;}
}