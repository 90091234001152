#expertise::before {
    content: '';
    display: none;
    height: 55px;
    margin-top: -55px;
    visibility: hidden;
}


.expertise {
    background-color: var(--background);
    height: 100vh; 
    width: 100%; 
    position: relative;
}

.expertise .content {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 1500px;
    margin: auto;
    /* padding: 1rem; */
    text-align: center;
    color: var(--plain);
}

.expertise .content .expertise-container-heading {
    display: inline-flex;
    margin: 0 auto;
    padding-bottom: 130px;
    
}

.expertise .content .expertise-container-heading h2 {
    font-size: 70px;
}

.expertise .content .expertise-container-heading h2:first-child {
    color: var(--primary);
    padding-right: 10px;
}

.expertise .content .expertise-container-heading h2:nth-child(2) {
    color: var(--secondary);
}

.expertise .content .expertise-content {
    padding: 0 0 0 10rem;
}

.expertise-content .container {
    display: grid;
    grid-template-columns: 33.33% 33.33% 33.33%;
    text-align: left;
}

.expertise-content .col-1 {
    display: flex;
    flex-direction: column;
}

.expertise-content .container ul li {
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-weight: 300;
    margin-bottom: 80px;
}

.expertise-content .container ul li img {
    vertical-align: middle;
    padding-right: 20px;
}

.expertise .content .expertise-btn {
    position: absolute;
    bottom: 3%;
    left: 0;
    right: 0;
}

/*--------------RESPONSIVE CSS ----------------*/
@media screen and (max-width:1024px) {
    .expertise .content .expertise-content {padding: 0 4.5rem;}
    .expertise-content .container ul li {font-size: 25px;}
    .expertise-content .container ul li img {padding-right: 10px; vertical-align: middle;}
}

@media screen and (max-width:940px) {
    #expertise::before {display: block;}
    .expertise {height: auto;}
    .expertise .content .expertise-container-heading {display:block; padding: 4rem 0;}
    .expertise .content .expertise-container-heading h2 {font-size: 40px;}
    .expertise .content .expertise-content {margin: 0 auto; padding: 0 0 2rem 0;}
    .expertise-content .container {grid-template-columns: 1fr;}
    .expertise-content .container ul li {font-size: 16px; margin-bottom: 40px;}
    .expertise-content .container ul li img {width: 50px;}
    .expertise .content .expertise-btn {bottom: 0;}
}

@media screen and (max-width:940px) and (orientation: landscape) {
    .expertise {height: auto;}
    .expertise .content .expertise-container-heading {padding: 5rem 0 2rem;}
    .expertise .content .expertise-btn {bottom: 0;}
}

@media screen and (max-width:375px) {
    .expertise {height: auto;}
    .expertise .content .expertise-container-heading {padding-top: 6rem;}

}