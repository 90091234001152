#contact::before {
    content: '';
    display: block;
    height: 85px;
    margin-top: -85px;
    visibility: hidden;
}

.contact {
    background-color: var(--contact);
    width: 100%; 
    color: var(--plain);
}

.contact a {   
    color: var(--plain);
    text-decoration: underline;
}

.contact .container {
    display: grid;
    grid-template-columns: 40% 60%;
}

.contact .container .col-1 {
    display: flex;
    flex-direction: column;
    position: relative;
    overflow: hidden;
}

.contact .container .col-1 img {
    position: absolute;
    transform: translateX(-65px);
}

.contact .container .col-2 {
    padding: 0 30px 0 0;
    margin: 2rem 0;
}

.contact .prime-details h3 {
    margin: 0;
}

.contact .container .col-2 h3 {
    padding: 0 100px;
}

.contact .container .col-2 h3,
.contact .prime-details h3 {
    font-size: 35px;
    color: var(--plain);
    letter-spacing: 2px;
}

.Form {
    margin-top: 60px;
    padding: 0 100px 50px 100px;
}

.Form .column-2 {
    display: inline-grid;
    grid-template-columns: 1fr 1fr;
    grid-column-gap: 10%;
    width: 100%;
}

.Form .column-2 > div {
    margin-bottom: 40px;
}

.hide-recaptcha {display: none;}
.recaptcha-container {display: block; margin-bottom: 10px;}
.recaptcha-container .text {color: #fff;font-size: 120%;font-weight: 300; margin-bottom: 10px;}

input[type="tel"],
input[type="email"],
input[type="text"],
#msg {
    font-size: 24px;
    color: var(--plain);
    font-weight: 300;
    font-family: 'Inter', sans-serif;
    width: 100%;
    padding: 12px 0;
    border-bottom: 1px solid white;
    background-color: var(--contact);
    margin-bottom: 5px;
}

#msg {
    background-color: transparent;
    width: 100%;
    padding: 10px 0;
    border: none;
    overflow: auto;
    outline: none;
    height: 6em;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
    resize: none; /*remove the resize handle on the bottom right*/    
    border-bottom: solid 1px #fff;
}

.input-group {
    margin-bottom: 50px;
}

label {
    color: var(--plain);
    font-size: 24px;
    font-weight: 300;
}

::placeholder {
    color: var(--plain);
    font-size: 24px;
    font-weight: 300;
}

::-webkit-input-placeholder {
    color: var(--plain);
    font-size: 24px;
    font-weight: 300;
}

:-moz-placeholder {
    color: var(--plain);
    font-size: 24px;
    font-weight: 300;
}

::-moz-placeholder {
    color: var(--plain);
    font-size: 24px;
    font-weight: 300;
}

:-ms-input-placeholder {  
    color: var(--plain);
    font-size: 24px;
    font-weight: 300;
}

::input-placeholder {  
    color: var(--plain);;
    font-size: 24px;
    font-weight: 300;
}

input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  border: 0px none transparent;
  border-bottom: 1px solid white;
  -webkit-text-fill-color: var(--plain);
  -webkit-box-shadow: 0 0 0px 1000px var(--contact) inset;
  transition: background-color 5000s ease-in-out 0s;
}

.prime-details .container {
    grid-template-columns: 50% 50%;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    color: var(--plain);
    padding: 0 100px;
    margin: 2rem 0;

}

.prime-details .container .col-1 h4 {
    font-weight: 100;
}

.prime-details .container .col-1 h4:nth-child(2){
    margin-top: 50px;
    margin-bottom: 0;
}

.prime-details .container .col-2 {
    padding: 0;
    margin: 0;
}

.prime-details .container .col-2 a {
    color: var(--plain);
}

.prime-details .container .col-2 a:hover {
    color: var(--black);
}

.prime-details .container .col-2 p {
    font-weight: 400;
}

.footer-menu {
    margin-top: 30px;
}

.footer-menu .container {
    grid-template-columns: 20% 15% 45%;
    justify-items: stretch;
    justify-content: center;     
}

.footer-menu .container .col:not(:last-child) {
    border-right: 1px solid white;
    text-align: center;
    
}
.footer-menu .container a {
    text-decoration: none;
}

.footer-menu .container a:hover {
    cursor: pointer;
    color: var(--black);
}

.footer-menu .container .column-3 {
    justify-self: center;
}

.soc-icons {
    text-align: right;
    padding: 0 140px;
    margin-top: 40px;
}
.soc-icons a {
    margin-right: 20px;
}

.soc-icons img {
    width: 70px;
}

.soc-icons img:nth-child(3) {
    margin-right: 10px;
}

img.hide-desktop.hide-portrait {display: none;}

.contact .container .Form .inputs .sendmsg-container {
    display: inline-flex;
    flex-direction: row;
    align-items: center;
}
.contact .container .Form .inputs .posted-msg {display: none;}
.contact .container .Form .inputs.posted .posted-msg {
    color: #fff;
    font-size: 140%;
    font-weight: 700;
    margin-left: 10px;
    display: initial;
}

.grecaptcha-badge { visibility: hidden; }

.sendmsg-container button {
    background-color: var(--background);
    color: var(--plain);
    border: 1px solid var(--background);
    border-radius: 50px;
    font-size: 24px;
    font-weight: 600;
    padding: 20px 35px;
    width: auto;
    margin-top: 0;
    transition: all 0.5s ease;
}

.sendmsg-container button:hover {
    cursor: pointer;
    background-color: var(--plain);
    color: var(--background);
    border: 1px solid var(--plain);
}

.sendmsg-container button.success {
    background-color: green;
    border: 1px solid green;
    cursor: default;
}

.sendmsg-container button.success:hover {
    background-color: green;
    color: var(--plain);
}

.sendmsg-container button.failure {
    background-color: red;
    border: 1px solid red;
    cursor: default;
}

.sendmsg-container button.failure:hover {
    background-color: red;
    color: var(--plain);
}

.sendmsg-container button.sending {    
    border: 1px solid var(--background);
    cursor: default;
}

.sendmsg-container button.sending:hover {
    background-color: var(--background);
    color: var(--plain);
}

.sendmsg-container button.sending .caption {
    color: transparent;
}

.sendmsg-container button.sending .spinner {
    display: initial;
  }

.sendmsg-container button .caption {
    position: relative;
}

.sendmsg-container button .spinner {
    margin: 0 auto;
    width: 100%;
    text-align: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    display: none;
  }
  
  .sendmsg-container button .spinner > div {
    width: 18px;
    height: 18px;
    background-color: var(--contact);
  
    border-radius: 100%;
    display: inline-block;
    -webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
    animation: sk-bouncedelay 1.4s infinite ease-in-out both;
  }
  
  .sendmsg-container button .spinner .bounce1 {
    -webkit-animation-delay: -0.32s;
    animation-delay: -0.32s;
  }
  
  .sendmsg-container button .spinner .bounce2 {
    -webkit-animation-delay: -0.16s;
    animation-delay: -0.16s;
  }
  
  @-webkit-keyframes sk-bouncedelay {
    0%, 80%, 100% { -webkit-transform: scale(0) }
    40% { -webkit-transform: scale(1.0) }
  }
  
  @keyframes sk-bouncedelay {
    0%, 80%, 100% { 
      -webkit-transform: scale(0);
      transform: scale(0);
    } 40% { 
      -webkit-transform: scale(1.0);
      transform: scale(1.0);
    }
}

/*--------------RESPONSIVE CSS ----------------*/
@media screen and (max-width:1024px) {
    .contact .container .col-1 img {transform: translateX(-180px);}
    .Form {padding: 0 0 0 2rem;}
    .prime-details .container {padding-left: 30px; padding-right: 0;}
}

@media screen and (max-width:940px) {
    #contact::before {display: none;}
    .contact .container {display: flex; flex-wrap: wrap;}
    .contact .container .col-1 {order:2;}
    .contact .container .col-2 {order:1; margin: 5rem 1rem 3rem 1rem; padding: 0;}
    .contact .container .col-2 h3 {font-size: 30px;}
    .contact .container .col-2 .prime-details {display: none;}
    .contact .container .col-1 {max-width: 100%;}
    .contact .container .col-1 img {position: unset;width: auto; height: 600px;transform: scale(1.5)}
    .Form {padding: 1rem; margin-top: 35px;}
    .Form .column-2 {display: block;}

    input[type="tel"],
    input[type="email"] {width: 100%;}

    input[type="text"], 
    #msg,
    label,
    ::input-placeholder {font-size: 15px;}

    .contact .container .col-2 h3 {padding: 0 50px; text-align: center;}

    input {margin-bottom: 25px;}
    
    input[type="submit"] {margin-bottom: 0; font-size: 22px;}

    .contact .container .col-3 {order:3;margin: 3rem 1rem;padding: 0; width: 100%;}
    .contact .container .col-3 .container {padding: 0; margin: 1rem 0;}
    .contact .container .col-3 h4, 
    .contact .container .col-3 p {font-size: 14px;}
    .contact .container .col-3 .col-1, 
    .contact .container .col-3 .col-2{order: unset;width: 50%;} 
    .contact .container .col-3 .prime-details h3 {text-align: center; margin-bottom: 30px;}

    .contact .container .col-3 .prime-details .container .col-1 h4:nth-child(2) {margin-top: 80px;}
    .contact .container .col-3 .prime-details .container .col-1 h4:nth-child(3) {margin-top: 30px;}
    .contact .container .col-3 .prime-details .container .col-2 {margin: 0;}

    .contact .container .col-3 .soc-icons {text-align: center; margin-top: 50px;padding: 0;}
    .contact .container .col-3 .soc-icons img {width: 50px; margin-right: 15px;}

    .contact .container .col-1,
    .contact .container .col-2,
    .contact .container .col-3 {width: 100%;}

    .soc-icons a {margin-right: 0;}

    .footer-menu.hide-desktop .container .col:not(:last-child) {border-right: none;}
    .footer-menu.hide-desktop .container a {font-size: 15px;font-weight: 100;}
    .footer-menu.hide-desktop .container .col:nth-child(1)::after {content: '|';font-size: 15px;margin:0 10px;font-weight: 100;}
    .footer-menu.hide-desktop .col.column-3 {text-align: center;}
}

@media screen and (max-width:767px) {
    .contact .container .Form .inputs .sendmsg-container {text-align: center; display: block;}
    .contact .container .Form .inputs .sendmsg-container .posted-msg {margin-top: 20px; display: inline-block;}
    .Form .column-2 > div,
    .input-group {
        margin-bottom: 20px;
    }
    .hide-desktop .footer-menu.hide-desktop .col p {font-size: 12px;}
}

@media screen and (max-width:940px) and (orientation: landscape) {
    .contact .container .col-3 .container {width: 45%;margin: 0 auto;padding-bottom: 15px;}
    .contact .container .col-1 img.hide-desktop.hide-portrait {display: block; height: 100%;transform: translateX(0px); width: 100%;}
    .hide-landscape {display: none;}

    .hide-desktop .footer-menu.hide-desktop .container { width: 100%;}
    
}

